@keyframes fadeInRows {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

tr {
  animation: fadeInRows 0.7s ease-in-out;
}
  

  
  