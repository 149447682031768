@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


html, body, #root, #defaultLayout, #guestLayout {
    min-height: 100vh;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}
    
/* RadioButtonStyles.css */

.radio-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-label input[type="radio"] {
    width: 20px; /* Increase the width to make the radio button larger */
    height: 20px; /* Increase the height to make the radio button larger */
    margin-right: 10px; /* Add some spacing between the radio button and label */
margin-left: 15px; 
}
  
  .radio-label label {
    font-size: 18px; /* Increase the label font size */
  }
  
@keyframes colorChange {
    0% {
      color: rgb(247, 247, 242);
    }
    50% {
      color: green;
    }
    100% {
      color: rgb(227, 227, 220);
    }
  }
  
.transparentdiv{

    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent background for the upper div */
  color: #000; /* Text color for the upper div */
}
.color-changing {
    animation: colorChange 2s infinite; /* Adjust the duration (2s) as needed */
  }
  

.cinput {
    outline: 0;
    background: #ffffff;
    width: 100%;
    border: 2px solid #e6e6e6;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    transition: all 0.3s;
}

.cinput:focus {
    border-color: #5b08a7;
}

.bttn,
.btn-add,
.btn-edit,
.btn-delete {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #5b08a7;
    border: 0;
    text-decoration: none;
    padding: 15px;
    color: #FFFFFF;
    font-size: 16px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}

.btn-block {
    width: 100%;
}

.btn-add,
.btn-edit,
.btn-delete{
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    border-radius: 4px;
}
.btn-add {
    background-color: #00a762;
}
.btn-delete {
    background-color: #b72424;
}

.btn-logout {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    color: #212121;
    transition: all 0.3s;
    border-radius: 6px;
}
.btn-logout:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.bttn:hover,
.bttn:active,
.bttn:focus {
    background: #5b08a7;
}

.text-center {
    text-align: center;
}

table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

table > thead > tr > th {
    text-align: left;
    padding: 0.5rem 0.5rem;
    background-color: #efefef;
}

table > tbody > tr > td {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #efefef;
    white-space: nowrap;
}

.card {
    background-color: #FFF;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.25rem 1.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.alert {
    padding: 1rem;
    background-color: #ff4040;
    color: white;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.notification {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 100;
    padding: 1rem 1.5rem;
    background-color: #00a762;
    color: white;
    border-radius: 0.5rem;
}

.salesman{

    height: 100vh;
   
   
}

.salesman .billdetail{

width: 100vw;
padding: 6px;
display: flex;
justify-content: space-between;
height: 110px;
max-height: 110px;
border: 1px solid gray;

}


.salesman-navbar{
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 50px;
    max-height: 50px;
    padding-top: 34px;
    padding-bottom: 34px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow:0px 0px 5px 0px #9E9E9E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    position: sticky;
    top: 0;
    z-index: 10;
    
 
   
    
    }
    .salesman-navbar .icons{
    
  
    width: 55px;
    max-width: 55px;
    min-width: 55px;
    height: 55px;
    max-height: 55px;
    min-height: 55px;
   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200%;
    background-color:rgba(6, 6, 190, 0.93);
   
    
    
    }
    
    .salesman-navbar .icons .iconsfont{
font-size: 27px;
color: #ffffff;
margin-bottom: 4px;
display: column;

    }

.salesman .categories{

display: flex;
justify-content:center;
align-items: center;

padding-left: 8px;
padding-right: 8px;
padding-bottom: 13px;

}

.salesman .products{

    display: flex;
    justify-content:center;
    align-items: center;
    
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 13px;
    }
    
    .salesman .categories  .reports{
        width: 150px;
        height: 150px;
        max-width: 150px;
        min-height: 150px;
        max-height: 150px;
        min-height: 150px;
        border-radius: 10%;
        
        
        margin-top: 13px;
        margin-left: 10px;
      
       
        justify-content: center;
        align-items: center;
        
         /* Prevent line breaks */
        text-overflow: ellipsis; 
        word-wrap: break-word;
        
        background-color: rgba(250, 251, 250, 0);
        font-size: 20px;
        font-family:'Times New Roman', Times, serif;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        
          transform: translateY(-2px);
          transition: transform 0.3s ease-in-out;
          
        }
.salesman .categories  .boxes{
width: 150px;
height: 150px;
max-width: 150px;
min-height: 150px;
max-height: 150px;
min-height: 150px;
border-radius: 10%;


margin-top: 13px;
margin-left: 10px;

display: flex;
justify-content: center;
align-items: center;

 /* Prevent line breaks */
text-overflow: ellipsis; 
overflow: hidden;

background-color: rgba(250, 251, 250, 0);
font-size: 20px;
font-family:'Times New Roman', Times, serif;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

  transform: translateY(-2px);
  transition: transform 0.3s ease-in-out;
  
}


.salesman .products  .productboxes{
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    min-height: 150px;
    border-radius: 4%;
    
    
    margin-top: 13px;
    margin-left: 10px;
    
    
    justify-content: center;
    align-items: center;
    
     /* Prevent line breaks */
    text-overflow: ellipsis; 
    overflow: hidden;
    
    background-color: whitesmoke;
    font-size: 20px;
    font-family:'Times New Roman', Times, serif;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4);
      transform: translateY(-2px);
      transition: transform 0.3s ease-in-out;
      
      padding: 10px;
    }


    .salesman .products  .productboxes:hover{
        width: 150px;
        height: 150px;
        max-width: 150px;
        min-height: 150px;
        max-height: 150px;
        min-height: 150px;
        border-radius: 4%;
        
        
        margin-top: 13px;
        margin-left: 10px;
        
        
        justify-content: center;
        align-items: center;
        
         /* Prevent line breaks */
        text-overflow: ellipsis; 
        overflow: hidden;
        color:black;
        background-color: rgb(245, 226, 226);
        font-size: 20px;
        font-family:'Times New Roman', Times, serif;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4);
          transform: translateY(-5px);
          transition: transform 0.2s ease-in-out;
          
          padding: 10px;
        }
    
 
.salesman .categories  .boxes:hover{
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    min-height: 150px;
    border-radius: 10%;
    
    
    margin-top: 13px;
    margin-left: 10px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
     /* Prevent line breaks */
    text-overflow: ellipsis; 
    overflow: hidden;
    
    background-color: rgba(250, 251, 250, 0);
    font-size: 20px;
    font-family:'Times New Roman', Times, serif;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    
      transform: translateY(-5px);
      transition: transform 0.3s ease-in-out;
    
    }
    
/* Login/Signup forms*/

.login-signup-form {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-signup-form .form {
    width: 360px;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    padding: 34px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.login-signup-form .title {
    font-size: 20px;
    margin-bottom: 1rem;
    text-align: center;
}

.login-signup-form .form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 16px;
    text-align: center;
}

.login-signup-form .form .message a {
    color: #5b08a7;
    text-decoration: none;
}

/* Login/Signup form */


#defaultLayout {
    display: flex;
}

#defaultLayout aside {
    width: 240px;
    background-color: #5b08a7;
    padding: 1rem
}

#defaultLayout aside > a {
    display: block;
    padding: 0.75rem 1rem;
    border-radius: 6px;
    color: white;
    text-decoration: none;
    transition: all 0.2s;
}

#defaultLayout aside > a:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

#defaultLayout .content {
    flex: 1;
}

#defaultLayout header {
    height: 80px;
    padding: 2rem 3rem;
    background-color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    
}

#defaultLayout main {
    padding: 2rem;
}

.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}