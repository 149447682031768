.shadow
{
    margin-top: 15px;
    box-shadow:0px 2px 2px #ccc;
    padding: 22px;
    
    height: 100px;
   
    
    text-align: center;
    transition-duration: 1s;
    border: 2px solid #eee;

   }
 
   .shadowgraph
{
    margin-top: 15px;
    box-shadow:0px 2px 2px #ccc;
    padding: 22px;
    
    height: 100%;
   overflow-x: none;
    
    text-align: center;
    transition-duration: 1s;
    border: 2px solid #eee;

   }
 

    .shadow:hover{

-ms-transform: scale(0.9); /* IE 9 */
-webkit-transform: scale(0.9); /* Safari 3-8 */
transform: scale(0.9); 
cursor: pointer;
}
